import {Table} from "antd";
import './sample-preview-list.css'
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate} from "../../../services/date-format";
import SampleStock from "../../../entities/sample-stock";
import ReceivableType from "../../../entities/receivable-type";
import {useSampleStockFilterService} from "../../archive-stock/service/use-sample-stock-filter-service";
import {DoLoadNextSampleStockQuery} from "../hooks/do-load-next-sample-stock";
import {useContext} from "react";
import {WorkingSystemConfigContext} from "../../../services/working-system-config-provider";

export const SamplePreviewList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sample = location.state.sample;
  const receivableTypes: ReceivableType[] = location.state.receivableTypes;
  const filterService = useSampleStockFilterService();
  const wscContext = useContext(WorkingSystemConfigContext);

  const sampleQuery = DoLoadNextSampleStockQuery({
    filters: filterService.value,
    id: sample.id,
    quantity: 5,
    quarter: wscContext.value.quarter,
    manager: wscContext.value.manager,
    quarterYear: wscContext.value.quarterYear,
  });

  const columns = [
    {
      title: 'Fundo',
      dataIndex: 'fund',
    },
    {
      title: 'Cedente',
      dataIndex: 'assignor',
    },
    {
      title: 'Sacado',
      dataIndex: 'drawee',
    },
    {
      title: 'Seu Número',
      dataIndex: 'yourNumber',
    },
    {
      title: 'Número Documento',
      dataIndex: 'documentNumber',
    },
    {
      title: 'Data Aquisição',
      dataIndex: 'acquisitionDate',
    },
    {
      title: 'Tipo Recebível',
      dataIndex: 'receivableType',
    }
  ];

  const tableData = () => {
    const samples = sampleQuery.data?.items ?? [];

    return samples.map((it: SampleStock) => ({
      key: it.id,
      fund: it.fund,
      assignor: it.assignorName,
      drawee: it.draweeName,
      yourNumber: it.yourNumber,
      documentNumber: it.documentNumber,
      acquisitionDate: formatDate(it.acquisitionDate),
      receivableType: receivableTypes?.find(x => x.id === it.receivableTypeId)?.code,
    }));
  }

  function onItemClick(record: any) {
    const samples = sampleQuery.data?.items ?? [];
    const s = samples.find((it: SampleStock) => it.id === record.key)!;

    navigate(
      `/archive-stock-page/item/${s.id}`,
      {
        state: {
          sample: s,
          receivableType: receivableTypes?.find(it => it.id === s.receivableTypeId),
          receivableTypes: receivableTypes,
        }
      }
    );
  }

  return (
    <Table
      className='table'
      loading={sampleQuery.isLoading}
      columns={columns}
      dataSource={tableData()}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: (_) => onItemClick(record),
        };
      }}
    />
  );
}
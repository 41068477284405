import SampleStock from "../../../entities/sample-stock";
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {SampleData} from "../../archive-stock/service/do-load-sample-stock-list";

async function doLoadSample({filters, id, quarter, manager, quarterYear}: any): Promise<SampleData> {
  const response = await instance.post(`/v1/sample_stock/previous/${id}`, filters, {
    params: {
      quarter: quarter,
      manager: manager,
      quarterYear: quarterYear,
    },
  });

  return {
    items: response.data.items.map(SampleStock.fromJson),
    pageNumber: response.data.pageNumber,
    totalItems: response.data.totalItems,
  };
}

export function DoLoadPreviousSampleStock() {
  return useMutation(doLoadSample);
}
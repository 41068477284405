import {Button, Col, DatePicker, Form, Input, Row, Select, Space, theme, TreeSelect} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ReceivableType from "../../../entities/receivable-type";
import dayjs from "dayjs";
import Fund from "../../../entities/fund";

export const SampleStockListFilter = (props: any) => {
  const [form] = useForm();
  const {token} = theme.useToken();
  const formStyle: React.CSSProperties = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const [prefix, setPrefix] = useState('');

  const onSelectStatus = (value: any) => {
    setPrefix('');

    if (!value) return;

    const parentOption = value.split('-')[0];
    statusOptions.forEach((option) => {
      if (option.value === parentOption) {
        setPrefix(option.title);
      }
    });
  }

  const statusOptions = [
    {
      value: '1',
      title: 'Cessão',
      selectable: false,
      children: [
        {
          value: '1-2',
          title: 'Solicitar Documento',
        },
        {
          value: '1-3',
          title: 'Divergente'
        },
        {
          value: '1-4',
          title: 'Pendente'
        },
      ]
    },
    {
      value: '2',
      title: 'Recebível',
      selectable: false,
      children: [
        {
          value: '2-2',
          title: 'Solicitar Documento'
        },
        {
          value: '2-3',
          title: 'Divergente'
        },
        {
          value: '2-4',
          title: 'Pendente'
        },
      ]
    },
    {
      value: '3',
      title: 'Doc. Origem',
      selectable: false,
      children: [
        {
          value: '3-2',
          title: 'Solicitar Documento'
        },
        {
          value: '3-3',
          title: 'Divergente'
        },
        {
          value: '3-4',
          title: 'Pendente'
        },
      ]
    }
  ];

  const receivableTypeOptions = () => {
    return props.receivableTypeList.map((it: ReceivableType) => {
      return {
        key: it.id,
        value: it.id,
        label: it.code,
      }
    });
  }

  const fundOptions = () => {
    return props.funds.map((it: Fund) => {
      return {
        key: it.id,
        value: it.id,
        label: it.name
      }
    })
  }

  const labelFilter = (input: any, option: any) : boolean => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    form.setFieldValue('fund', props.filters?.fund);
    form.setFieldValue('assignor', props.filters?.assignor);
    form.setFieldValue('drawee', props.filters?.drawee);
    form.setFieldValue('yourNumber', props.filters?.yourNumber);
    form.setFieldValue('documentNumber', props.filters?.documentNumber);
    form.setFieldValue('receivableType', props.filters?.receivableType);
    form.setFieldValue('hideStatusList', props.filters?.hideStatusList?.map((it: any) => it.toString()));
    form.setFieldValue('acquisitionDate', props.filters?.acquisitionDate?.map((it: any) => dayjs(it)));
    form.setFieldValue('situation', props.filters?.situation);
    onSelectStatus(props.filters?.situation);
  }, [props.filters]);


  return (
    <Form form={form} name="advanced_search" style={formStyle} onFinish={props.onSubmitFilter} layout='vertical'>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='fund' label='Fundo'>
          <Select
             allowClear
             mode="multiple"
             options={fundOptions()}
             filterOption={labelFilter}
          >
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='assignor' label='Cedente'>
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='drawee' label='Sacado'>
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='yourNumber' label='Seu Número'>
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='documentNumber' label='Número Documento'>
            <Input allowClear/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='receivableType' label='Tipo Recebível'>
            <Select
              allowClear
              options={receivableTypeOptions()}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='hideStatusList' label='Ocultar já auditados'>
            <Select allowClear mode="multiple">
              <Select.Option value='1'>Cessão</Select.Option>
              <Select.Option value='2'>Recebível</Select.Option>
              <Select.Option value='3'>Doc. Origem</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='acquisitionDate' label='Período Aquisição'>
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Data inicial', 'Data final']}
              style={{width: '100%'}}/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='situation' label='Status'>
            <TreeSelect
              allowClear
              treeData={statusOptions}
              onChange={onSelectStatus}
              prefix={prefix ? `${prefix} > ` : ''}
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={{textAlign: 'right'}}>
        <Space size="small">
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Limpar
          </Button>
          <Button type="primary" htmlType="submit">
            Aplicar
          </Button>
        </Space>
      </div>
    </Form>
  );
}

SampleStockListFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  receivableTypeList: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  funds: PropTypes.array.isRequired,
}
import {App} from "antd";
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const loadWalletReport = async (options: any) => {
  await instance.post('/v1/report/wallet', options);
}

export function DoLoadWalletReport() {
  const {notification} = App.useApp();
  return useMutation(loadWalletReport, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );
}
import {Button, List, Skeleton} from "antd";
import React, {useState} from "react";
import {BaseContent} from "../../components/base-content";
import {ConfirmModal} from "../../components/confirm-modal";
import {DoLoadManagers} from "../../hooks/do-load-managers";
import {DoChangeManagerStatus} from "./hooks/do-change-manager-status";
import ManagerFormModal from "./components/manager-form-modal";
import {Manager} from "../../entities/manager";
import {DoLoadFunds} from "../../hooks/do-load-funds";

export default function ManagerManagementPage() {
  const [formVisible, setFormVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const managerQuery = DoLoadManagers();
  const deleteManagerQuery = DoChangeManagerStatus();
  const [manager, setManager] = useState<Manager>();
  const fundsQuery = DoLoadFunds({});

  function onEditTap(u: Manager) {
    setManager(u);
    setFormVisible(true);
  }

  function onChangeStatus(u: Manager) {
    setManager(u);
    setDeleteModalVisible(true);
  }

  async function changeStatusSubmit() {
    if (!manager) return;

    setDeleteModalVisible(false);
    await deleteManagerQuery.mutateAsync(manager);
    setManager(undefined);
    await managerQuery.refetch();
  }

  return (
    <BaseContent>
      {
        managerQuery.isLoading ||
        deleteManagerQuery.isLoading
          ? <Skeleton/>
          : (<List
            pagination={{position: 'bottom', align: 'center'}}
            header={<Button type="primary" onClick={() => setFormVisible(true)}>Novo administrador</Button>}
            dataSource={managerQuery.data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a onClick={() => onEditTap(item)}>Editar</a>,
                  <a onClick={() => onChangeStatus(item)}>{item.dataStatus === 1 ? 'Desativar' : 'Ativar'}</a>
                ]}
              >
                <List.Item.Meta
                  title={`${item.name} ${item?.dataStatus !== 1 ? '(Inativo)' : ''}`}
                />
              </List.Item>
            )}
          />)
      }
      <ManagerFormModal
        formVisible={formVisible}
        manager={manager}
        funds={fundsQuery.data ?? []}
        onClose={async () => {
          setFormVisible(false);
          setManager(undefined);
          await managerQuery.refetch();
        }}
      />
      <ConfirmModal
        title={manager?.dataStatus === 1 ? 'Inativar Gestor?' : 'Ativar Gestor?'}
        isVisible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => changeStatusSubmit()}
      />
    </BaseContent>
  );
}
import React, {useContext, useEffect} from 'react'
import {Form, Modal, Select, Space} from "antd";
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";
import PropTypes from "prop-types";
import {DoLoadManagers} from "../../hooks/do-load-managers";

export default function SystemConfigModal({callback, isVisible}: any) {
  const [form] = Form.useForm();
  const wscContext = useContext(WorkingSystemConfigContext);
  const managerQuery = DoLoadManagers();

  useEffect(() => {
    form.setFieldValue(['quarter'], wscContext.value.quarter);
    form.setFieldValue(['manager'], wscContext.value.manager);
    form.setFieldValue(['quarterYear'], wscContext.value.quarterYear);
  }, [wscContext.value]);

  const onSubmit = () => {
    wscContext.setValue({
      quarter: form.getFieldValue(['quarter', 'quarter']),
      manager: form.getFieldValue('manager'),
      quarterYear: form.getFieldValue(['quarter', 'year']),
    });

    form.resetFields();
    callback();
  }

  const managerOptions = () => {
    return (managerQuery.data ?? []).map(it => ({
      key: it.id,
      value: it.id,
      label: it.name,
    }));
  }

  const yearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    let year = 2024;
    while (year < currentYear + 5) {
      years.push(year++);
    }

    return years.map(it => ({
      key: `year-${it}`,
      value: it,
      label: it,
    }));
  }

  return (
    <Modal
      open={isVisible}
      destroyOnClose={true}
      centered={true}
      okText={'Confirmar'}
      closable={false}
      onCancel={undefined}
      cancelButtonProps={{style: {display: 'none'}}}
      onOk={form.submit}
    >
      <Form
        form={form}
        labelCol={{span: 6}}
        wrapperCol={{span: 16}}
        style={{width: '100%'}}
        onFinish={onSubmit}
      >
        <h3>Configuração inicial</h3>
        <Form.Item required={true} label='Trimestre'>
          <Space.Compact style={{width:'100%'}}>
            <Form.Item name={['quarter', 'quarter']} noStyle>
              <Select placeholder='Trimestre'>
                <option value="1">Primeiro</option>
                <option value="2">Segundo</option>
                <option value="3">Terceiro</option>
                <option value="4">Quarto</option>
              </Select>
            </Form.Item>
            <Form.Item
              name={['quarter', 'year']}
              noStyle
              initialValue={new Date().getFullYear()}
            >
              <Select placeholder='Ano' options={yearOptions()}/>
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item name='manager' label='Administrador' rules={[{required: true}]}>
          <Select options={managerOptions()} style={{width: '100%'}} placeholder='Administrador'/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

SystemConfigModal.prototype = {
  callback: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
import {instance} from "../../../services/axios-config";
import {useMutation, useQuery} from "react-query";
import {SampleData} from "../../archive-wallet/service/do-load-sample-wallet-list";
import SampleWallet from "../../../entities/sample-wallet";


async function doLoadSamples({filters, id, quantity, quarter, manager, quarterYear}: any): Promise<SampleData> {
  const response = await instance.post(`/v1/sample_wallet/next/${id}`, filters, {
    params: {
      quantity: quantity,
      quarter: quarter,
      manager: manager,
      quarterYear: quarterYear,
    },
  });

  return {
    items: response.data.items.map(SampleWallet.fromJson),
    pageNumber: response.data.pageNumber,
    totalItems: response.data.totalItems,
  };
}

export function DoLoadNextSampleWallet() {
  return useMutation(doLoadSamples);
}

export function DoLoadNextSampleWalletQuery(query: any) {
  return useQuery(
    `/v1/sample_wallet/next/${query.id}`,
    () => doLoadSamples(query),
    {
      staleTime: 60 * 1000,
    }
  );
}
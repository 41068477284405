import {instance} from "../../../services/axios-config";
import {App} from "antd";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const deleteDocument = async (uid: string) => {
  await instance.delete(`/v1/sample_wallet/file/${uid}/delete`);
}

export const DoDeleteDocument = () => {
  const {notification} = App.useApp();
  return useMutation(deleteDocument, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );
}
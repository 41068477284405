import React, {useContext, useEffect, useState} from 'react';
import {Breadcrumb, Divider, Table, Tag} from 'antd';
import './index.css'
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate} from "../../services/date-format";
import {DoLoadReceiveTypes} from "../../hooks/do-load-receivable-types";
import {BaseContent} from "../../components/base-content";
import {SampleWalletListFilter} from "./components/sample-wallet-list-filter";
import {useSampleWalletFilterService} from "./service/use-sample-wallet-filter-service";
import {HomeOutlined} from "@ant-design/icons";
import {DoLoadSampleWalletList} from "./service/do-load-sample-wallet-list";
import { DoLoadFunds } from '../../hooks/do-load-funds';
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";

const columns = [
  {
    title: 'Tipo Recebível',
    dataIndex: 'receivableType',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '260px',
    render: (_: any, {items}: any) => (
      <>
        {
          items.map((it: any) => {
            let color = 'darkgrey';
            if (it.status > 0) {
              color = 'green';
            }

            return (
              <Tag color={color} key={it.code}>{it.code}</Tag>
            )
          })
        }
      </>
    )
  },
  {
    title: 'Fundo',
    dataIndex: 'fund',
  },
  {
    title: 'Emitente',
    dataIndex: 'emitter',
  },
  {
    title: 'Papel',
    dataIndex: 'documentPaper',
  },
  {
    title: 'Código',
    dataIndex: 'documentCode',
  },
  {
    title: 'Proposta',
    dataIndex: 'proposal',
  },
  {
    title: 'Data aplicação',
    dataIndex: 'applicationDate',
  },
];

export default function ArchiveWalletPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const wscContext = useContext(WorkingSystemConfigContext);

  const filterService = useSampleWalletFilterService();
  const [query, setQuery] = useState<any>({
    filters: filterService.value,
    page: 1,
    quarter: wscContext.value.quarter,
    manager: wscContext.value.manager,
    quarterYear: wscContext.value.quarterYear
  });
  const sampleQuery = DoLoadSampleWalletList(query);
  const receivableTypeQuery = DoLoadReceiveTypes();
  const fundsQuery = DoLoadFunds({manager: wscContext.value.manager});
  const sampleRedirect = location.state?.sampleRedirect;

  const tableData = () => {
    return (sampleQuery.data?.items ?? []).map((it, index) => ({
      key: index,
      fund: it.fund,
      emitter: it.emitter,
      documentPaper: it.documentPaper,
      documentCode: it.documentCode,
      proposal: it.proposal,
      applicationDate: formatDate(it.applicationDate),
      receivableType: receivableTypeQuery.data?.find(x => x.id === it.receivableTypeId)?.code,
      items: it.items,
    }))
  }

  const onFilterSubmit = (formValues: any) => {
    formValues.hideStatusList = formValues?.hideStatusList?.map((it:any) => Number(it)) ?? [];
    filterService.setValue(formValues);
    setQuery((ps: any) => {
      ps.filters = formValues;
      return ps;
    });
  }

  function onItemClick(i: number) {
    const s = sampleQuery.data!.items[i];

    navigate(
      `/archive-wallet-page/item/${s.id}`,
      {
        state: {
          sample: s,
          receivableType: receivableTypeQuery.data?.find(it => it.id === s.receivableTypeId),
          receivableTypes: receivableTypeQuery.data,
        }
      }
    );
  }

  useEffect(() => {
    if (sampleRedirect !== undefined) {
      navigate(
        `/archive-wallet-page/item/${sampleRedirect.id}`,
        {
          state: {
            sample: sampleRedirect,
            receivableType: receivableTypeQuery.data?.find(it => it.id === sampleRedirect.receivableTypeId),
            receivableTypes: receivableTypeQuery.data,
          }
        }
      );
    }
  }, [sampleRedirect]);

  useEffect(() => {
    sampleQuery.refetch();
  }, [filterService.value, query]);

  useEffect(() => {
    fundsQuery.refetch();
    sampleQuery.refetch();
  }, []);


  return (
    <BaseContent>
      <Breadcrumb
        items={[
          {title: <HomeOutlined />, href: '/'},
          {title: 'Auditoria de carteira'}
        ]}
      />
      <Divider/>
      <div>
        <SampleWalletListFilter
          onSubmitFilter={onFilterSubmit}
          receivableTypeList={receivableTypeQuery.data ?? []}
          filters={filterService.value}
          funds={fundsQuery.data ?? []}
        />
        <Table
          onRow={(record, _) => {
            return {
              onClick: (_) => onItemClick(record.key),
            };
          }}
          className='archive-page-table'
          columns={columns}
          dataSource={tableData()}
          loading={sampleQuery.isLoading || receivableTypeQuery.isLoading || sampleQuery.isRefetching}
          pagination={{
            total: sampleQuery.data?.totalItems ?? 0,
            defaultCurrent: query.page,
            onChange: (page, qty) => {
              setQuery((ps: any) => ({
                filters: ps.filters,
                page: page,
                quantity: qty,
                quarter: ps.quarter,
                manager: ps.manager,
                quarterYear: ps.quarterYear,
              }));
            }
          }}
        >
        </Table>
      </div>
    </BaseContent>
  );
}
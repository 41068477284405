import {instance} from "../../../services/axios-config";
import {useQuery} from "react-query";
import SampleWallet from "../../../entities/sample-wallet";

export interface SampleData {
  items: SampleWallet[];
  pageNumber: number;
  totalItems: number;
}

async function doLoadSampleWalletList({filters, page, quantity, quarter, manager, quarterYear}: any): Promise<SampleData> {
  const response = await instance.post(
    '/v1/sample_wallet',
    filters,
    {
      params: {
        page,
        quantity,
        quarter,
        manager,
        quarterYear,
      },
    }
  );

  return {
    items: response.data.items.map(SampleWallet.fromJson),
    pageNumber: response.data.pageNumber,
    totalItems: response.data.totalItems,
  };
}

export function DoLoadSampleWalletList(filters: any) {
  return useQuery(
    '/v1/sample_wallet',
    () => doLoadSampleWalletList(filters),
    {
      staleTime: 60 * 1000,
    }
  );
}
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {SampleData} from "../../archive-wallet/service/do-load-sample-wallet-list";
import SampleWallet from "../../../entities/sample-wallet";

async function doLoadSample({filters, id, quarter, manager, quarterYear}: any): Promise<SampleData> {
  const response = await instance.post(`/v1/sample_wallet/previous/${id}`, filters, {
    params: {
      quarter: quarter,
      manager: manager,
      quarterYear: quarterYear,
    },
  });

  return {
    items: response.data.items.map(SampleWallet.fromJson),
    pageNumber: response.data.pageNumber,
    totalItems: response.data.totalItems,
  };
}

export function DoLoadPreviousSampleWallet() {
  return useMutation(doLoadSample);
}